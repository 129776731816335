import React from 'react';
import moment from 'moment';

import { DataEvent } from './interfaces';
import { getEventClassName, eventIcon, dateIsEmpty } from './index';

interface Props {
    data: DataEvent;
}

const DATE_FORMAT = 'DD.MM.YYYY HH:mm';


const getIcon = (status: String | Boolean) => {
    return status === 'unset'
        ? 'icon-question'
        : status
        ? 'icon-check'
        : 'icon-reject';
};

const confirmation = [
    ['finance', 'Коммерческая служба'],
    ['flightService', 'Летная служба'],
    ['ias', 'Инженерная служба'],
    ['pds', 'Диспетчерская служба']
];

export default ({ data }: Props) => {
    const { title, flight, type, startDate, endDate, text } = data;
    const icon = eventIcon(data);
    /*const icon = type.indexOf('Рейс') !== -1 ? 'icon-flight-white' : '';*/
    const headClass = getEventClassName(data);
        /*type.indexOf('Рейс') !== -1 ? 'event-flight' : getEventClassName(data);*/
    /*const flightConfirmation = flight.confirmation;*/
    const flightConfirmation = false;
    const sortedCrew = flight &&
        flight.crew && [
         ...flight.crew.filter((item: any) => item.role === 'КВС-Инструктор'),
         ...flight.crew.filter((item: any) => item.role === 'КВС'),
         ...flight.crew.filter((item: any) => item.role === 'Второй пилот'),
         ...flight.crew.filter((item: any) => item.role === 'Бортпроводник-инструктор'),
         ...flight.crew.filter((item: any) => item.role === 'Бортпроводник')
        ]
        ;

    return (
        <div className="modal">
            <div className="modal-bg"></div>
            <div className="modal-window">
                <div className={`modal-head ${headClass}`}>
                    {icon && <div className={`modal-icon icon ${icon}`}></div>}
                    {title}
                </div>
                <div className="modal-body">
                    {(type.indexOf('Рейс') !== -1 && flight && (
                        <div className="modal-text">
                            <div style={{ fontWeight: 'bold' }}>
                                {flight.aircraft}
                            </div>
                            <div style={{ marginBottom: '0.5rem' }}>
                                {flight.aircraftType}
                            </div>
                            <div>Рейс: {flight.flightNumber}</div>
                            <div>Маршрут: {flight.route}</div>
                            <div style={{ marginBottom: '0.5rem' }}>
                                Количество пассажиров: {flight.pax}
                            </div>
                            <div>
                                ETD: {getFormattedDate(flight.ETD)}
                            </div>
                            <div>
                                ATD: {getFormattedDate(flight.ATD)}
                            </div>                            
                            <div>
                                ETA: {getFormattedDate(flight.ETA)}
                            </div>
                            <div style={{ marginBottom: '0.5rem' }}>
                                ATA: {getFormattedDate(flight.ATA)}
                           </div>                                       
                            {flightConfirmation && (
                                <div style={{ marginBottom: '0.5rem' }}>
                                    {confirmation.map((item: any) => (
                                        <div
                                            key={item[0]}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <span
                                                style={{ marginRight: '5px' }}
                                                className={`icon icon-inline ${getIcon(
                                                    flight.confirmation[item[0]]
                                                )}`}
                                            />{' '}
                                            {item[1]}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {sortedCrew && (
                                <div>
                                    <div>Экипаж:</div>
                                    {sortedCrew.map(
                                        (item: any, index: number) => (
                                            <div key={index}>
                                                {item.role} - {item.name}
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    )) || (
                        <div className="modal-text">
                            {(type !== "Окончание документа") && (<div>
                                Начало:{' '}
                                {moment.utc(startDate).format(DATE_FORMAT)}
                            </div>)}
                            <div>{omitUIDs(text)}</div>
                            {(type !== "Увольнение") && (
                                <div>
                                    Окончание:{' '}
                                    {moment.utc(endDate).format(DATE_FORMAT)}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

function getFormattedDate(dateTime: moment.MomentInput) {
    let result = moment.utc(dateTime).format(DATE_FORMAT)
    if (dateIsEmpty(dateTime)) {
        return ''
    }
    return result
}

function omitUIDs(txt: string) {
    if (txt === null) {
        return ''
    }
    let uidStart = txt.indexOf('[')
    if (uidStart == -1){
        return txt
    }
   return txt.slice(0, uidStart)}