export const DATA_EVENT_NAME = 'newDataFromOneS';

function makeid(length: number) {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

interface DataForOneS {
    type: string;
    payload?: any;
}

class Connector {
    public connectionElement: HTMLElement;
    private _data: any;
    private _id: string;

    constructor() {
        this._id = makeid(6);
        const connectionElement = document.createElement('div');
        connectionElement.style.display = 'none';
        connectionElement.id = this._id;
        document.body.appendChild(connectionElement);
        this.connectionElement = connectionElement;
    }

    public get id() {
        return this._id;
    }

    public get data() {
        return this._data;
    }

    public sendDataToApp = (data: string) => {
        const event = new CustomEvent(DATA_EVENT_NAME, {
            detail: JSON.parse(data)
        });
        window.dispatchEvent(event);
    };

    public sendDataToOneS = (data: DataForOneS) => {
        console.log('Sending data to 1C', data); //tslint: disable-line
        this._data = JSON.stringify(data);
        this.connectionElement.click();
    };

    public subscribe(handler: (event: CustomEvent) => void) {
        window.addEventListener(DATA_EVENT_NAME, handler as EventListener);
    }

    public unsubscribe(handler: (event: CustomEvent) => void) {
        window.removeEventListener(DATA_EVENT_NAME, handler as EventListener);
    }
}

const connector = new Connector();
(window as any).oneSConnector = {
    id: connector.id,
    sendDataToApp: connector.sendDataToApp,
    sendDataToOneS: connector.sendDataToOneS,
    getData: () => connector.data
};

export default connector;
