import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { reducer, initialState } from './reducer';
import logger from './logger';
import { StateProvider } from './state';
import oneSConnector from './oneSConnector';

ReactDOM.render(
    <StateProvider
        initialState={initialState}
        reducer={
            process.env.NODE_ENV === 'development' ? logger(reducer) : reducer
        }
    >
        <App />
    </StateProvider>,
    document.getElementById('root')
);

if (process.env.NODE_ENV === 'development') {
    oneSConnector.sendDataToApp(
        JSON.stringify({
            type: 'setApiConfig',
            payload: {
                uri: 'https://denis.1c.najet.ru/hs/rpc',
                credentials: 'YWRtaW46MTIzNDU='
            }
        })
    );
}
