import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    h3 {
        margin-top: 0;
        margin-bottom: 0.3rem;
    }
`;

export default ({ title, text, id }: any) => {
    return (
        <Wrapper>
            <div className="employee">
                <div className="employee-name">{title}</div>
                <div className="employee-position" dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
        </Wrapper>
    );
};
