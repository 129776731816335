import axios from 'axios';
import moment, { Moment } from 'moment';
import { aircraftsData, crewData } from './testData';

const mockData = { aircraftsData, crewData };

interface ApiConfig {
    uri: string;
    credentials: string;
    userId: string;
}

interface GetTimelineDataArguments {
    startDate: string | Date | Moment;
    endDate: string | Date | Moment;
    objectType: 'aircraft' | 'crew';
}

const apiFactory = (apiConfig: ApiConfig) => ({
    getTimelineData: getTimelineData(apiConfig),
    getTimelineOptions: getTimelineOptions(apiConfig),
    closeNotice: closeNotice(apiConfig),
    uri: apiConfig.uri, // ДЛЯ КОСТЫЛЯ В reducer::432
});

const getTimelineData = (apiConfig: ApiConfig) => async ({
    startDate,
    endDate,
    objectType
}: GetTimelineDataArguments) => {
    if (!apiConfig) {
        return;
    }
    if (process.env.NODE_ENV === 'development') {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    result: objectType === 'aircraft' ? aircraftsData : crewData
                });
            }, 3000);
        });
    }

    const response = await axios.post(
        apiConfig.uri,
        {
            jsonrpc: '2.0',
            method: 'get_timeline_data',
            params: {
                objectType,
                startDate: moment(startDate).toISOString(),
                endDate: moment(endDate).toISOString()
            },
            id: 1
        },
        {
            headers: { Authorization: `Basic ${apiConfig.credentials}` }
        }
    );
    return response.data;
};

const getTimelineOptions = (apiConfig: ApiConfig) => async () => {
    if (process.env.NODE_ENV === 'development') {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    result: {
                        updateInterval: 30000,
                        userRoles: ['ias', 'crew', 'finance', 'pds'],
                        dbName: 'dev'
                    }
                });
            }, 1000);
        });
    }

    const response = await axios.post(
        apiConfig.uri,
        {
            jsonrpc: '2.0',
            method: 'get_timeline_options',
            params: {
                userId: apiConfig.userId
            },
            id: 1
        },
        {
            headers: { Authorization: `Basic ${apiConfig.credentials}` }
        }
    );
    return response.data;
};

const closeNotice = (apiConfig: ApiConfig) => async (id: string) => {
    if (process.env.NODE_ENV === 'development') {
        return new Promise((resolve) => {
            const { aircraftsData, crewData } = mockData;
            aircraftsData.notices = [
                ...aircraftsData.notices.filter((notice) => notice.id !== id)
            ];
            crewData.notices = [
                ...crewData.notices.filter((notice) => notice.id !== id)
            ];

            setTimeout(() => {
                resolve({
                    result: true
                });
            }, 1000);
        });
    }

    const response = await axios.post(
        apiConfig.uri,
        {
            jsonrpc: '2.0',
            method: 'close_notice',
            params: {
                id
            },
            id: 1
        },
        {
            headers: { Authorization: `Basic ${apiConfig.credentials}` }
        }
    );
    return response.data;
};

export default apiFactory;
