import React, { useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import Timeline from './Timeline';
import moment, { Moment } from 'moment';
import { TimelineItem } from 'vis-timeline';
import 'moment/locale/ru';

import { EventEmitter, getTimelineData, setRangePeriod } from '../reducer';
import Tooltip from './Tooltip';
import GroupLabel from './GroupLabel';
import Item from './Item';
import { TimelineGlobalStyle } from './TimelineGlobalStyle';
import {
    Data,
    DataEvent,
    DataConflict,
    TimelineEvent,
    ExtendedTimeline,
    DataWorkshift
} from './interfaces';
import {
    clickHandler,
    changedHandler,
    rangeChangedHandler,
    currentTimeTickHandler,
    selectHandler,
    rangeChangeHandler,
    noticeSelectHandler
} from './handlers';
import { useStateValue } from '../state';
import { getDate } from 'date-fns';

export interface TimelineDates {
    start: moment.Moment;
    end: moment.Moment;
}

export type TimelineRef = React.RefObject<ExtendedTimeline>;


const emptyDate = '0001-01-01T00:00:00';
const emptyDateZ = '0001-01-01T00:00:00Z';
const OPTIONS = {
    locale: 'ru',
    locales: {
        ru: {}
    },
    moment: function(date: string | Date | Moment) {
        return moment(date).utc();
    },
    preferZoom: true,
    verticalScroll: true,
    orientation: { axis: 'top', item: 'top' },
    margin: { axis: 10, item: { horizontal: 0, vertical: 10 } },
    width: '100%',
    height: '100%',
    // minHeight: '100%',
    stack: false,
    showMajorLabels: true,
    showCurrentTime: true,
    zoomable: true,
    moveable: true,
    editable: false,
    maxMinorChars: 3,
    zoomMin: 1000 * 60 * 60,
    zoomMax: 1000 * 60 * 60 * 24 * 365,
    format: {
        minorLabels: {
            hour: 'HH'
        },
        majorLabels: {
            hour: 'DD.MM.YY'
        }
    },
    tooltip: {
        followMouse: true,
        overflowMethod: 'cap'
    },
    template: function(item: any) {
        return item.type !== 'background'
            ? renderToString(<Item item={item} />)
            : renderToString(
                  <div
                      style={{
                          overflow: 'hidden',
                          width: '100%',
                          height: '100%'
                      }}
                      title={item.conflict}
                  >
                      {/* {item.initialData.id} */}
                  </div>
              );
    },
    groupTemplate: function(item: any) {
        return renderToString(
            <GroupLabel title={item.title} text={item.text} id={item.id} />
        );
    }
};

export const getEventClassName = (
    event: DataEvent,
    conflicts?: Data['conflicts']
) => {
    let eventType = 'event-default';
    let flightisFinished = false;
    let crewComplet = false;
    let isNajet = false;
    let airborneStatus = '';
    if (event.parent === 'Рейс' || event.type === 'Рейс') {
        airborneStatus = getAirborneStatus(event)
        switch (event.flight.status) {
            case 'Заявка':
                if (event.flight.pax === 0) { 
                    eventType = "event-flight-option";
                } else {
                    eventType = "event-flight-option-with-pax";
                }
                break;
            case 'Забронирован':
                if (event.flight.pax === 0) { 
                    eventType = "event-flight-booked";
                } else {
                    eventType = "event-flight-booked-with-pax";
                }
                break;
            case 'Подтвержден':    
                if (event.flight.pax === 0) { 
                    eventType = "event-flight-white";
                } else {
                    eventType = "event-flight";
                }
                break;
            default:
                eventType = "event-flight ";
        }
        if (event.flight.isFinished) {
            flightisFinished = true;
        }

        if (event.flight.mainCrewComplet) {
            crewComplet = true;
        }
    } 
    else if (event.parent  === 'Рейс' || event.type === 'Рейс NAJET') {
        isNajet = true
        switch (event.flight.status) {
            case 'Заявка':
                if (event.flight.pax === 0) { 
                    eventType = "event-flight-option";
                } else {
                    eventType = "event-flight-option-with-pax";
                }
                break;
            case 'Забронирован':
                if (event.flight.pax === 0) { 
                    eventType = "event-flight-booked";
                } else {
                    eventType = "event-flight-booked-with-pax";
                }
                break;
            case 'Подтвержден':    
                if (event.flight.pax === 0) { 
                    eventType = "event-flight-white";
                } else {
                    eventType = "event-flight";
                }
                break;
            default:
                eventType = "event-flight";
        }
        if (event.flight.isFinished) {
            flightisFinished = true;
        }

        if (event.flight.mainCrewComplet) {
            crewComplet = true;
        }
    } else {
        switch (event.parent || event.type) {
            case 'Неисправность':
                eventType = 'event-defect';
                break;
            case 'ТО':
                eventType = 'event-maintenance';
                break;
            case 'Болезнь':
                eventType = 'event-illness';
                break;
            case 'Окончание документа':
                eventType = 'event-document';
                break;
            case 'Медкомиссия':
                eventType = 'event-medical';
                break;
            case 'Обучение':
                eventType = 'event-education';
                break;
            case 'Отпуск':
                eventType = 'event-vacation';
                break;
            case 'Выходной':
                eventType = 'event-weekend';
                break;
            default:
                eventType = 'event-default';
        }
    }

    const conflict = !!(conflicts || []).filter(
        (item) =>
            item.objectId === event.groupId && item.events.includes(event.id)
    ).length;
    const confirmation = event.flight && event.flight.confirmation;
    const hasUnsetStatus =
        confirmation &&
        !!Object.keys(confirmation).filter(
            (key: string) => confirmation[key] === 'unset'
        ).length;
    const hasRejectStatus =
        confirmation &&
        !!Object.keys(confirmation).filter((key: string) => !confirmation[key])
            .length;
    return `${eventType} ${conflict ? 'event-conflict' : ''} ${
         flightisFinished
            ? 'event-finished'
            : hasUnsetStatus && (crewComplet === true)
            ? 'event-questionCrew'
            : hasUnsetStatus && (crewComplet === false)
            ? 'event-question'
            : hasRejectStatus && (crewComplet === true)
            ? 'event-rejectCrew'
            : hasRejectStatus && (crewComplet === false)
            ? 'event-reject'
            : ''
        } ${
            isNajet 
                ? ''
                : ''
            } ${airborneStatus}`;

};

function getAirborneStatus(event: DataEvent) {
    let utcAnyway = event.startDate;
    //if (utcAnyway.indexOf('Z') > -1) {
    //    utcAnyway=utcAnyway+"Z"
    //}
    
    //console.log("   ")
    //console.log(moment(utcAnyway))
    //console.log(moment.utc(Date.now()))

    if (moment.utc(utcAnyway) <= moment.utc(Date.now())){
        if (dateIsEmpty(event.flight.ATD)){
            return 'is-delayed-departure'
        }
        else {
            if (!dateIsEmpty(event.flight.ATA)){
                return 'arrived' }
            
            else {return 'airborne'}
        }
    }
    else{
        return ''
    }
    //return 'is-delayed-arrival'
}


export function dateIsEmpty(dateTime: moment.MomentInput) {
    return dateTime === emptyDate || dateTime === emptyDateZ
}

function searchStringInArray(str: string, ...strArray: string[]) {
    return strArray.indexOf(str) > -1;
}

export const eventIcon = (event: DataEvent) => {
    if (searchStringInArray('Рейс', event.type)) {
        let eventIcon = 'icon-flight-airplane'
        let iconColor = 'svg-filter-white'
        if (event.flight.pax === 0) {
            iconColor = getIconColor(event.flight.status)
        }
        return eventIcon + ' ' + iconColor
    } else {
        return ''
    }
}

function getIconColor(eventStatus: string) {
    switch (eventStatus) {
        case "Заявка":
            return 'svg-filter-purple'
        case "Забронирован":
            return 'svg-filter-blue'
        case "Подтвержден":
            return 'svg-filter-green'
        default:
            return 'svg-filter-white'
    }
}

function setEventColor(eventType: string, eventStatus: string) {

}

const eventText = (event: DataEvent) => {
    //if (event.type.indexOf('Рейс') !== -1) {
    if (searchStringInArray('Рейс', event.type)) {
        if (event.flight.flightNumber) {
            return `${event.flight.flightNumber}<br/>${event.flight.departureAirport}&#8211;${event.flight.arrivalAirport}`;
        } else {
            return `${event.flight.departureAirport}&#8211;${event.flight.arrivalAirport}`;
        } 
    } else if (searchStringInArray('Рейс NAJET', event.type)) {
        if (event.flight.flightNumber) {
            return `${event.flight.flightNumber}<br/>${event.flight.departureAirport}&#8211;${event.flight.arrivalAirport}`;
        } else {
            return `${event.flight.departureAirport}&#8211;${event.flight.arrivalAirport}`;
        }
    } else if (event.type !== 'Увольнение') {
        return `${event.type}<br/>до ${moment
            .utc(event.endDate)
            .format('DD.MM.YYYY')}`;
    } else {
        return ''
    }
};

const mapWorkshiftsToItems = (data: Data) =>
    ((data && data.workshifts) || []).map((event: DataWorkshift) => ({
        initialData: { ...event },
        id: `${event.id}__${event.groupId}`,
        start: moment.utc(event.startDate),
        end: moment.utc(event.endDate),
        group: event.groupId,
        type: 'background',
        className: `event-workshift ${event.conflict ? 'conflict' : ''}`,
        conflict: event.conflict
    }));

const mapConflictsToItems = (
    data: Data,
    eventTypeFilter: string,
    groups: any
) =>
    ((data && data.conflicts) || [])
        .map((conflict: DataConflict) => {
            const [eventId1, eventId2] = conflict.events;
            const event1 =
                eventId1 &&
                data.events.find(
                    (event) =>
                        event.groupId === conflict.objectId &&
                        event.id === eventId1
                );
            const event2 =
                eventId2 &&
                data.events.find(
                    (event) =>
                        event.groupId === conflict.objectId &&
                        event.id === eventId2
                );
            if (
                !event1 ||
                !event2 ||
                (event1 &&
                    eventTypeFilter &&
                    event1.type !== eventTypeFilter) ||
                (event2 && eventTypeFilter && event2.type !== eventTypeFilter)
            ) {
                return null;
            }
            const event1Start = moment.utc(event1.startDate);
            const event1End = moment.utc(event1.endDate);
            const event2Start = moment.utc(event2.startDate);
            const event2End = moment.utc(event2.endDate);
            let intersectionStart, intersectionEnd;
            if (event2Start >= event1Start && event2End <= event1End) {
                intersectionStart = event2Start;
                intersectionEnd = event2End;
            } else if (event2Start <= event1Start && event2End >= event1End) {
                intersectionStart = event1Start;
                intersectionEnd = event1End;
            } else if (event2Start >= event1Start && event2End >= event1End) {
                intersectionStart = event2Start;
                intersectionEnd = event1End;
            } else {
                intersectionStart = event1Start;
                intersectionEnd = event2End;
            }
            const item: TimelineEvent = {
                start: intersectionStart,
                end: intersectionEnd,
                group: conflict.objectId,
                conflict: true,
                firstLine: groups.length && groups[0].id === conflict.objectId,
                selectable: false,
                text: '',
                content: '',
                className: 'conflict-item'
            };
            return item;
        })
        .filter((item): item is TimelineEvent => !!item);

const mapDataToItems = (data: Data, eventTypeFilter: string) =>
    ((data && data.events) || [])
        .filter(
            (event) =>
                !eventTypeFilter ||
                event.parent === eventTypeFilter ||
                event.type === eventTypeFilter
        )
        .map<TimelineEvent>((event) => ({
            initialData: { ...event },
            id: `${event.id}__${event.groupId}`,
            title: renderToString(<Tooltip data={event} />),
            start: moment.utc(event.startDate),
            end: moment.utc(event.endDate),
            group: event.groupId,
            className: getEventClassName(event, data.conflicts),
            name: event.title,
            text: eventText(event),
            content: '',
            icon: eventIcon(event)
            //    (searchStringInArray('Рейс NAJET', event.type) || searchStringInArray('Рейс', event.type))
            //    //event.type.indexOf('Рейс') !== -1
            //            ? event.flight.pax !== 0
            //            ? 'icon-flight-white'
            //            : 'icon-flight-blue'
            //        : ''
        }));

const sortByOrder = (a: any, b: any) =>
        a.order > b.order ? 1 : a.order < b.order ? -1 : 0;

const mapDataToGroups = (
    data: Data,
    crewRoleFilter: string,
    searchFilter: string
) =>
    ((data && data.groups) || [])
        .filter(
            (group) =>
                !crewRoleFilter ||
               (group.roles && group.roles.includes(crewRoleFilter))
        )
        .filter(
            (group) =>
                !searchFilter ||
                group.title.toLowerCase().includes(searchFilter.toLowerCase())
        )
        .sort(sortByOrder) 
        .map((group) => {
            return {
                id: group.id,
                content: '',
                title: group.title,
                text: group.text
            };
    
        });

const getOptions = (startDate: Moment, endDate: Moment) => {
    const options: any = {
        ...OPTIONS,
        start: startDate,
        end: endDate
    };
    return options;
};

const useWindowResize = (
    timelineRef: TimelineRef,
    startDate: Moment,
    endDate: Moment
) => {
    useEffect(() => {
        const handleWindowResize = () => {
            if (timelineRef.current) {
                timelineRef.current.setOptions(getOptions(startDate, endDate));
            }
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [timelineRef, startDate, endDate]);
};

export default () => {
    const [state, dispatch] = useStateValue();
    const {
        data,
        currentTab,
        startDate,
        endDate,
        eventTypeFilter,
        crewRoleFilter,
        searchFilter,
        reloadCounter,
        updateCounter,
        loading,
        updateInterval,
        showWorkshifts
    } = state;
    const timelineRef = useRef<ExtendedTimeline>(null);
    const groups = mapDataToGroups(
        data,
        (currentTab === 'crew' && crewRoleFilter) || '',
        (currentTab === 'crew' && searchFilter) || ''
    );
    let items = [
        ...mapDataToItems(data, eventTypeFilter),
        ...mapConflictsToItems(data, eventTypeFilter, groups)
    ];
    if (showWorkshifts) {
        items = [...items, ...mapWorkshiftsToItems(data)] as TimelineEvent[];
    }
    const options = getOptions(startDate, endDate);

    useWindowResize(timelineRef, startDate, endDate);

    useEffect(() => {
        const timeline = timelineRef.current;
        const _selectHandler = selectHandler(timelineRef, currentTab);
        const _currentTimeTickHandler = currentTimeTickHandler(timelineRef);
        const _clickHandler = clickHandler(currentTab);
        const _rangeChangedHandler = rangeChangedHandler(dispatch);
        const _noticeSelectHandler = noticeSelectHandler(
            timelineRef,
            currentTab,
            dispatch
        );
        EventEmitter.subscribe('noticeSelect', _noticeSelectHandler);
        if (timeline) {
            timeline.on('click', _clickHandler);
            timeline.on('rangechanged', _rangeChangedHandler);
            timeline.on('rangechange', rangeChangeHandler);
            timeline.on('currentTimeTick', _currentTimeTickHandler);
            timeline.on('changedHanlder', changedHandler);
            timeline.on('select', _selectHandler);
        }
        return () => {
            if (timeline) {
                timeline.off('click', _clickHandler);
                timeline.off('rangechanged', _rangeChangedHandler);
                timeline.off('rangechange', rangeChangeHandler);
                timeline.off('currentTimeTick', _currentTimeTickHandler);
                timeline.off('changedHanlder', changedHandler);
                timeline.off('select', _selectHandler);
                EventEmitter.unsubscribe('noticeSelect', _noticeSelectHandler);
            }
        };
    }, [timelineRef, currentTab, dispatch]);

    useEffect(() => {
        const timeline = timelineRef.current;
        timeline && timeline.setData({ items, groups } as any);
        // eslint-disable-next-line
    }, [
        timelineRef,
        reloadCounter,
        eventTypeFilter,
        crewRoleFilter,
        searchFilter,
        showWorkshifts
    ]);

    useEffect(() => {
        if (timelineRef.current) {
            timelineRef.current.setOptions(options);
            timelineRef.current.setItems((items as unknown) as TimelineItem[]);
        }
    }, [timelineRef, updateCounter, startDate, endDate]); // eslint-disable-line

    useEffect(() => {
        if (!currentTab) {
            return;
        }
        const cancelToken = { isCancelled: false };
        getTimelineData(dispatch, currentTab, cancelToken);
        const intervalId =
            updateInterval &&
            setInterval(
                () => getTimelineData(dispatch, currentTab, cancelToken),
                updateInterval
            );
        return () => {
            cancelToken.isCancelled = true;
            intervalId && clearInterval(intervalId);
        };
    }, [updateInterval, currentTab, dispatch]);

    return (
        <>
            {loading && <div className="loading">Данные обновляются...</div>}
            <div
                className="timeline"
                id="timeline"
                style={{ display: loading ? 'none' : 'flex' }}
            >
                <TimelineGlobalStyle />
                <EventFocus timelineRef={timelineRef} />
                <Timeline ref={timelineRef} />
            </div>
        </>
    );
};

interface EventFocusProps {
    timelineRef: React.RefObject<ExtendedTimeline>;
}

const EventFocus: React.SFC<EventFocusProps> = ({ timelineRef }) => {
    const [state, dispatch] = useStateValue();
    const { focusEvent } = state;
    useEffect(() => {
        if (focusEvent && !focusEvent.waitingData && timelineRef.current) {
            timelineRef.current.focus(focusEvent.id, { duration: 500 });
        }
    }, [timelineRef, focusEvent]);

    useEffect(() => {
        const timeline = timelineRef.current;
        const rangeChangedHandler = (props: any) => {
            if (!props.byUser && focusEvent) {
                if (timeline) {
                    const currentWindow = timeline.getWindow();
                    setRangePeriod(
                        dispatch,
                        moment(currentWindow.start),
                        moment(currentWindow.end)
                    );
                    dispatch({
                        type: 'setFocusEvent',
                        payload: null
                    });
                }
            }
        };
        if (timeline) {
            timeline.on('rangechanged', rangeChangedHandler);
        }
        return () => {
            if (timeline) {
                timeline.off('rangechanged', rangeChangedHandler);
            }
        };
    }, [timelineRef, focusEvent, dispatch]);

    return null;
};
