import React, { useEffect } from 'react';

import Tabs from './Tabs';
// import User from './User';
import Filters from './Filters';
import ClientLogo from './ClientLogo';
import Footer from './Footer';
import Timeline from './Timeline';
import './style.css';
import { onecDataHandler as _onecDataHandler } from './reducer';
import { useStateValue } from './state';
import oneSConnector from './oneSConnector';

export default () => {
    const [{ clientName }, dispatch] = useStateValue();
    useEffect(() => {
        const onecDataHandler = (event: CustomEvent) => {
            _onecDataHandler(dispatch, event.detail);
        };
        oneSConnector.subscribe(onecDataHandler);
        return () => {
            oneSConnector.unsubscribe(onecDataHandler);
        };
    }, [dispatch]);

    return (
        <div id="page">
            <header>
                <ClientLogo name={clientName} />
                <Tabs />
                {/* <User /> */}
            </header>
            <section>
                <details>
                    <summary>Фильтры</summary>
                    <Filters />
                </details>    
                <Timeline />
            </section>
            <Footer />
        </div>
    );
};
