const getDate = (daysDiff = 0, hoursDiff = 0) =>
    new Date(
        new Date().getTime() +
            1000 * 3600 * 24 * daysDiff +
            1000 * 3600 * hoursDiff
    );

export const notices = [
    {
        id: 'notice-1',
        date: getDate().toISOString(),
        text:
            '<b>Новый рейс</b> для VQ-BAN.<br>\n\nМаршрут: UUWW-ULLI.\n<br>\nВылет 30.10.19, 00:00.',
        event_id: 'event-1',
        object_id: 'aircraft-1',
        notice_type: 'НовыйРейс',
        roles: ['finance'],
        action_required: false
    },
    {
        id: 'notice-2',
        date: getDate(0, 2).toISOString(),
        text:
            '<b>Новый рейс</b> для VQ-BAN.<br>\nМаршрут: UUWW-ULLI.<br>\nВылет 30.10.19, 00:00.<br>\n<b>Подтвердите готовность ВС.</b>',
        event_id: 'event-1',
        object_id: 'aircraft-1',
        notice_type: 'НовыйРейсПодтвердитеГотовностьВС',
        roles: ['ias'],
        action_required: true
    },
    {
        id: 'notice-3',
        date: getDate(0, 1).toISOString(),
        text:
            '<b>Новый рейс</b> для VQ-BAN.<br>\nМаршрут: UUWW-ULLI.<br>\nВылет 30.10.19, 00:00.<br>\n<b>Подберите экипаж.</b>',
        event_id: 'event-1',
        object_id: 'aircraft-1',
        notice_type: 'НовыйРейсПодберитеЭкипаж',
        roles: ['crew'],
        action_required: true
    }
];

export const aircraftsData = {
    groups: [
        {
            id: 'aircraft-1',
            title: 'VQ-BAN',
            text: 'HAWKER 750',
            order: 4
        },
        {
            id: 'aircraft-2',
            title: 'VQ-BBH',
            text: 'HAWKER 750',
            order: 3
        },
        {
            id: 'aircraft-3',
            title: 'VQ-BBL',
            text: 'HAWKER 750',
            order: 2
        }
    ],
    events: [
        {
            id: 'event-1',
            groupId: 'aircraft-1',
            flightFolderNumber: '',
            startDate: getDate(),
            endDate: getDate(0, 4),
            title: 'Рейс',
            type: 'Рейс',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BAN',
                pax: 1,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: 2
            }
        },
        {
            id: 'event-2',
            groupId: 'aircraft-1',
            flightFolderNumber: '',
            startDate: getDate(0, 3),
            endDate: getDate(0, 8),
            title: 'Неисправность',
            type: 'Неисправность',
            text: null
        },
        {
            id: 'event-112',
            groupId: 'aircraft-1',
            flightFolderNumber: '',
            startDate: getDate(0, 33),
            endDate: getDate(0, 40),
            title: 'ТО',
            type: 'ТО',
            text: null
        },
        {
            id: 'event-3',
            groupId: 'aircraft-1',
            flightFolderNumber: '',
            startDate: getDate(0, 10),
            endDate: getDate(0, 15),
            title: 'Рейс',
            type: 'Рейс',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BAN',
                pax: 0,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),                
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: 1
            }
        },
        {
            id: 'event-4',
            groupId: 'aircraft-1',
            flightFolderNumber: '',
            startDate: getDate(0, 17),
            endDate: getDate(0, 23),
            title: 'Рейс',
            type: 'Рейс',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BAN',
                pax: 0,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),                
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: "Забронирован"
            }
        },
        {
            id: 'event-5',
            groupId: 'aircraft-1',
            flightFolderNumber: '',
            startDate: getDate(-10, 25),
            endDate: getDate(-10, 30),
            title: 'Рейс',
            type: 'Рейс',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BAN',
                pax: 1,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ1',
                ETD: getDate(-10, 25),
                ATD: getDate(-10, 25),
                ETA: getDate(-10, 30),
                ATA: new Date(1,1,1),
                //ATA: getDate(-10, 30),                
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: "Забронирован"
            }
        },
        {
            id: 'event-11',
            groupId: 'aircraft-2',
            flightFolderNumber: '',
            startDate: getDate(),
            endDate: getDate(0, 4),
            title: 'Рейс',
            type: 'Рейс NAJET',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BBH',
                pax: 1,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: new Date("0001-01-01 00:00"),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),                
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: 1
            }
        },
        {
            id: 'event-13',
            groupId: 'aircraft-2',
            flightFolderNumber: '',
            startDate: getDate(0, 10),
            endDate: getDate(0, 15),
            title: 'Рейс',
            type: 'Рейс NAJET',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BBH',
                pax: 0,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: 1
            }
        },
        {
            id: 'event-14',
            groupId: 'aircraft-2',
            flightFolderNumber: '',
            startDate: getDate(0, 17),
            endDate: getDate(0, 23),
            title: 'Рейс',
            type: 'Рейс NAJET',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BBH',
                pax: 0,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: "Забронирован"
            }
        },
        {
            id: 'event-15',
            groupId: 'aircraft-2',
            flightFolderNumber: '',
            startDate: getDate(0, 25),
            endDate: getDate(0, 30),
            title: 'Рейс',
            type: 'Рейс NAJET',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BBH',
                pax: 1,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: "Забронирован"
            }
        }
    ],
    conflicts: [
        {
            objectId: 'aircraft-1',
            type: 'event',
            events: ['event-1', 'event-2']
        }
    ],
    notices,
    params: {},
    period: {
        startDate: '2019-01-01T00:00:00Z',
        endDate: '2019-12-31T23:59:59Z'
    }
};

export const crewData = {
    groups: [
        {
            id: 'person-1',
            title: 'Абрамов А. И.',
            text: '',
            roles: ['КВС']
        },
        {
            id: 'person-2',
            title: 'Бабарыкин С. И.',
            text: '',
            roles: []
        },
        {
            id: 'person-3',
            title: 'Белов А. И.',
            text: '',
            roles: ['Второй пилот']
        },
        {
            id: 'person-4',
            title: 'Бурмистров С. И.',
            text: '',
            roles: ['КВС', 'Второй пилот']
        },
        {
            id: 'person-5',
            title: 'Важов С. И.',
            text: '',
            roles: ['Второй пилот', 'КВС']
        },
        {
            id: 'person-6',
            title: 'Габец С. И.',
            text: '',
            roles: ['Бортпроводник']
        },
        {
            id: 'person-7',
            title: 'Дабул С. И.',
            text: ''
        },
        {
            id: 'person-8',
            title: 'Евгушкин А. И.',
            text: ''
        },
        {
            id: 'person-9',
            title: 'Жабенко А. И.',
            text: ''
        },
        {
            id: 'person-10',
            title: 'Забарин А. И.',
            text: ''
        }
    ],
    events: [
        {
            id: 'event-1',
            groupId: 'person-5',
            flightFolderNumber: '',
            startDate: getDate(1),
            endDate: getDate(1, 2),
            title: 'Рейс',
            type: 'Рейс',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BAN',
                pax: 6,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ],
                status: "Забронирован"
            }
        },
        {
            id: 'event-2',
            groupId: 'person-6',
            flightFolderNumber: '',
            startDate: getDate(1),
            endDate: getDate(1, 2),
            title: 'Рейс',
            type: 'Рейс',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BAN',
                pax: 6,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ]
            }
        },
        {
            id: 'event-3',
            groupId: 'person-7',
            flightFolderNumber: '',
            startDate: getDate(1),
            endDate: getDate(1, 2),
            title: 'Рейс',
            type: 'Рейс',
            text: null,
            flight: {
                flightNumber: 'YUP-3601',
                aircraftType: 'HAWKER 750',
                aircraft: 'VQ-BAN',
                pax: 6,
                departureAirport: 'UUWW',
                departureAirportName: 'ВНУКОВО',
                arrivalAirport: 'ULLI',
                arrivalAirportName: 'ПУЛКОВО',
                route: 'ВНУКОВО (UUWW) МОСКВА - ПУЛКОВО (ULLI) САНКТ-ПЕТЕРБУРГ',
                ETD: getDate(),
                ATD: getDate(),
                ETA: getDate(0, 4),
                ATA: getDate(0, 4),
                crew: [
                    {
                        role: 'КВС',
                        name: 'Важов С. И.'
                    },
                    {
                        role: 'Второй пилот',
                        name: 'Габец С. И.'
                    },
                    {
                        role: 'Бортпроводник',
                        name: 'Дабул С. И.'
                    }
                ]
            }
        },
        {
            id: 'event-4',
            groupId: 'person-10',
            flightFolderNumber: '',
            startDate: getDate(0),
            endDate: getDate(0, 6),
            title: 'Обучение',
            type: 'Обучение',
            text: null
        },
        {
            id: 'event-5',
            groupId: 'person-10',
            flightFolderNumber: '',
            startDate: getDate(0, 4),
            endDate: getDate(0, 12),
            title: 'Болезнь',
            type: 'Болезнь',
            text: null
        },
        {
            id: 'event-111',
            groupId: 'person-10',
            flightFolderNumber: '',
            startDate: getDate(0, 14),
            endDate: getDate(0, 14),
            title: 'Окончание документа',
            type: 'Окончание документа',
            text: 'Превед'
        }
    ],
    workshifts: [
        {
            id: 'workshift-1',
            groupId: 'person-5',
            startDate: getDate(1, -1),
            endDate: getDate(1, 3)
        },
        {
            id: 'workshift-2',
            groupId: 'person-6',
            startDate: getDate(1, -1),
            endDate: getDate(1, 3),
            conflict: 'test conflict'
        }
    ],
    conflicts: [
        {
            objectId: 'person-10',
            type: 'event',
            events: ['event-4', 'event-5']
        }
    ],
    notices,
    params: {},
    period: {
        startDate: '2019-01-01T00:00:00Z',
        endDate: '2019-12-31T23:59:59Z'
    }
};
