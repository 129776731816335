import React, { useRef, useLayoutEffect, forwardRef } from 'react';
import vis, { TimelineOptions } from 'vis-timeline';
import 'vis-timeline/dist/vis-timeline-graph2d.min.css';

import { ExtendedTimeline } from './interfaces';

interface Props {
    options?: TimelineOptions;
    items: any[];
    groups?: any[];
}

export default forwardRef<ExtendedTimeline>((_, ref) => {
    const timelineNodeRef = useRef<HTMLDivElement>(null);
    const timelineRef = ref as React.MutableRefObject<ExtendedTimeline>;
    useLayoutEffect(() => {
        if (timelineNodeRef.current && timelineRef) {
            timelineRef.current = new vis.Timeline(
                timelineNodeRef.current,
                []
            ) as ExtendedTimeline;
        }
    }, [timelineNodeRef, timelineRef]);

    return <div ref={timelineNodeRef} />;
});
