import React from 'react';

import { Tab } from '../reducer';

interface Props {
    data: Tab;
    active?: boolean;
    onClick: (tabId: string) => void;
    onAction: (type: string) => void;
}

export default ({ data, active = false, onClick, onAction }: Props) => {
    const handleActionClick = (action: Tab['actions'][0]) => (
        event: React.SyntheticEvent
    ) => {
        event.stopPropagation();
        onAction(action.type);
    };
    return (
        <div
            className={`menu-item ${active ? 'menu-item-active' : ''}`}
            onClick={() => onClick(data.id)}
            id={data.id + "Tab"}
        >
            <div className="menu-item-title">{data.title}</div>
            {active && (
                <div className="controls">
                    {data.actions.map((action) => (
                        <button
                            key={action.type}
                            className="button button-big"
                            onClick={handleActionClick(action)}
                        >
                            {action.title}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};
