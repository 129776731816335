import moment from 'moment';

import { TimelineRef } from './interfaces';
import oneSConnector from '../oneSConnector';
import { Notice, Action, selectTab } from '../reducer';

export const selectHandler = (timelineRef: TimelineRef, currentTab: string) => (props: any) => {
    const itemId = props.items[0];
    if (!timelineRef.current!.itemSet.items[itemId]) {
        return;
    }
    const data = timelineRef.current!.itemSet.items[itemId].data.initialData;
    oneSConnector.sendDataToOneS({
        type: currentTab === 'finance' ? 'selectOrder' : 'select',
        payload: data
    });
};

export const currentTimeTickHandler = (timelineRef: TimelineRef) => () => {
    timelineRef.current!.currentTime.bar.setAttribute(
        'data-time',
        moment(timelineRef.current!.getCurrentTime())
            .utc()
            .format('HH:mm:ss')
    );
};

export const clickHandler = (currentTab: string) => (props: any) => {
    if (props.what === 'group-label') {
        oneSConnector.sendDataToOneS({
            type: currentTab === 'crew' ? 'selectEmployee' : 'selectAircraft',
            payload: { id: props.group }
        });
    }
};

export const changedHandler = () => {
    //const svg = createSvg(timelineRef);
    //drawConnectionPaths(timelineRef, svg);
};

export const noticeSelectHandler = (
    timelineRef: TimelineRef,
    currentTab: string,
    dispatch: React.Dispatch<Action>
) => (notice: Notice) => {
    if (!timelineRef.current) {
        return;
    }
    const eventId = `${notice.event_id}__${notice.object_id}`;
    let needTabChange = !notice.roles.includes(currentTab);
    if (notice.notice_type === 'НовыйРейсПодберитеЭкипаж') {
        oneSConnector.sendDataToOneS({
            type: 'select',
            payload: { id: notice.event_id }
        });
        return;
    }
    if (needTabChange) {
        dispatch({
            type: 'setFocusEvent',
            payload: {
                id: eventId,
                waitingData: true
            }
        });
        selectTab(dispatch, notice.roles[0]);
    } else {
        dispatch({
            type: 'setFocusEvent',
            payload: {
                id: eventId,
                waitingData: false
            }
        });
    }
};

export const rangeChangedHandler = (dispatch: React.Dispatch<Action>) => ({
    start,
    end,
    byUser
}: any) => {
    if (byUser) {
        dispatch({
            type: 'changeDate',
            payload: {
                startDate: moment.utc(start),
                endDate: moment.utc(end)
            }
        });
    }
};

export const rangeChangeHandler = ({ byUser }: any) => {
    // console.log(
    //     'range change',
    //     scrollTop.current,
    //     timelineRef.current._getScrollTop()
    // );
    // scrollTop.current = timelineRef.current._getScrollTop();
};
