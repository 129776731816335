import { createGlobalStyle } from 'styled-components';

export const TimelineGlobalStyle = createGlobalStyle`
    .vis-timeline {
        visibility: visible !important;
        overflow: visible !important;
    }

    .vis-panel.vis-left .vis-label .vis-inner {
        height: 80px;
    }

    .vis-time-axis .vis-text.vis-major {
        font-weight: 500;
        color: #000;
    }
    .vis-time-axis .vis-text.vis-minor {
        font-size: 12px;
    }

    .vis-current-time {
        background-color: #4f1515;
        z-index: 5;
    }
    .vis-current-time:before {
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: #4f1515 2px solid;
        color: #4f1515;
        padding: 2px;
        background: #d5d5d5;
        line-height: 1;
        font-size: 12px;
        display: block;
        content: attr(data-time);
        transform: translateX(-50%);
        white-space: nowrap;
    }

    .vis-labelset .vis-label:nth-child(2n) {
        background: #fff;
    }
    .vis-labelset .vis-label:nth-child(2n + 1) {
        background: #f1f1f1;
    }
    .vis-background .vis-group:nth-child(2n + 1) {
        background:  rgba(255, 255, 255, 0.5);
    }
    .vis-background .vis-group:nth-child(2n) {
        background: rgba(230, 230, 230, 0.5);
    }
 
    .vis-labelset .vis-label .vis-inner {
        padding: 5px 10px 5px 30px;
        line-height: 1.3;
    }
    .vis-labelset .vis-label {
        border-color: #a3a3a3;
    }
    .vis-panel.vis-bottom,
    .vis-panel.vis-center,
    .vis-panel.vis-left,
    .vis-panel.vis-right,
    .vis-panel.vis-top {
        border-color: #a3a3a3;
    }
    .vis-time-axis .vis-grid.vis-major {
        border-color: #a3a3a3;
    }
    .vis-time-axis .vis-grid.vis-minor {
        border-color: #a3a3a3;
    }
    .vis-time-axis .vis-text.vis-major {
        padding-top: 20px;
    }
    .vis-timeline {
        border-left: 0;
        border-right: 0;
        border-color: #a3a3a3;
        background: #d5d5d5;
    }

    .vis-item {
        font-size: 12px;
        height: 40px;
        cursor: pointer;
    }
    .vis-item.vis-range {
        border-width: 3px;
        border-color: transparent;
        border-radius: 0;
    }
    .vis-item .vis-item-overflow {
        overflow: visible;
    }
    .vis-item .vis-item-content {
        padding: 5px;
        height: 100%;
        width: inherit;
    }
    .vis-center .vis-foreground {
        /* z-index: 3; */
    }
    div.vis-tooltip {
        font-size: 12px;
        border: none;
        box-shadow: none;
        background: none;
        padding: 0;
        margin: 0 20px;
    }
`;
