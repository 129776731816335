import React from 'react';

export default ({ item }: any) => {
    if (item.conflict) {
        return (
            <div
                className={`balloon balloon-warning ${
                    item.firstLine ? 'first-line' : ''
                }`}
            >
                <div className="balloon-icon icon icon-warning-white"></div>
                Конфликт
            </div>
        );
    }
    return (
        <>
            <div className="event-content-name">
                {item.icon && (
                    <div className={`event-content-icon icon ${item.icon}`} />
                )}{' '}
                {item.name}
            </div>
            {item.text && (
                <div
                    className="event-content-text"
                    dangerouslySetInnerHTML={{
                        __html: item.text.replace('\n', '<br/>')
                    }}
                />
            )}
        </>
    );
};
