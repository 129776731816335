import React from 'react';

import {
    selectTab as selectTabAction,
    handleTabAction,
    reloadTimeline
} from '../reducer';
import { useStateValue } from '../state';
import Tab from './Tab';

export default () => {
    const [state, dispatch] = useStateValue();
    const selectTab = (tabId: string) => {
        if (tabId === state.currentTab) {
            reloadTimeline(dispatch);
            return;
        }
        selectTabAction(dispatch, tabId);
    };
    const handleAction = (type: string) => handleTabAction(type);
    return (
        <div className="menu">
            {state.tabs
                .filter((tab: any) => state.userRoles.indexOf(tab.id) !== -1)
                .map((tab: any) => (
                    <Tab
                        key={tab.id}
                        data={tab}
                        active={state.currentTab === tab.id}
                        onClick={selectTab}
                        onAction={handleAction}
                    />
                ))}
        </div>
    );
};
