import React from 'react';

import Logo from './img/najet.png';

export default () => {
    return (
        <footer>
            <img className="logo" src={Logo} alt="NAJET" />
            <div className="contacts">
                <span>Техническая поддержка:</span>
                <span>
                    Тел.: <a href="tel:+74951208020">8 (495) 120 80 20</a>
                </span>
                <span>
                    Email:{' '}
                    <a href="mailto:tech-support@najet.ru">
                        tech-support@najet.ru
                    </a>
                </span>
            </div>
        </footer>
    );
};
