import oneSConnector from './oneSConnector';
import moment, { Moment } from 'moment';
import { compareDesc, parseISO } from 'date-fns';
import apiFactory from './api';

let API: any = null;

export interface Tab {
    id: 'crew' | 'ias' | 'finance' | 'pds' | 'simple';
    title: string;
    actions: { title: string; type: string }[];
}

export interface Notice {
    id: string;
    date: string;
    event_id: string;
    object_id: string;
    notice_type: string;
    text: string;
    roles: string[];
    action_required: boolean;
}

export interface State {
    clientName: string | null;
    currentTab: Tab['id'] | null;
    tabs: Tab[];
    data: any;
    notices: Notice[];
    startDate: Moment;
    endDate: Moment;
    userRoles: Tab['id'][];
    searchFilter: string;
    eventTypes: string[];
    eventTypeFilter: string;
    crewRoleFilter: string;
    crewRoles: string[];
    selectedNotice: Notice | null;
    reloadCounter: number;
    updateCounter: number;
    loading: boolean;
    focusEvent: {
        id: string;
        waitingData: boolean;
    } | null;
    updateInterval: number;
    showWorkshifts: boolean;
}

interface Options {
    updateInterval: State['updateInterval'];
    userRoles: State['userRoles'];
    clientName: string | null;
}

const LOAD_TIMELINE_DATA_REQUEST = 'LOAD_TIMELINE_DATA_REQUEST';
const LOAD_TIMELINE_DATA_SUCCESS = 'LOAD_TIMELINE_DATA_SUCCESS';
const LOAD_TIMELINE_DATA_FAILURE = 'LOAD_TIMELINE_DATA_FAILURE';

const LOAD_TIMELINE_OPTIONS_SUCCESS = 'LOAD_TIMELINE_OPTIONS_SUCCESS';
const LOAD_TIMELINE_OPTIONS_FAILURE = 'LOAD_TIMELINE_OPTIONS_FAILURE';

const SELECT_TAB = 'SELECT_TAB';

export const initialState: State = {
    clientName: null,
    startDate: moment.utc().startOf('isoWeek'),
    endDate: moment.utc().endOf('isoWeek'),
    currentTab: 'crew',
    userRoles: [],
    tabs: [
        {
            id: 'crew',
            title: 'Летная служба',
            actions: [
                {
                    title: '+ Сотрудник',
                    type: 'newCrewEmployee'
                },
                {
                    title: 'События',
                    type: 'crewEvents'
                },
                {
                    title: '+ Событие',
                    type: 'newCrewEvent'
                }
            ]
        },
        {
            id: 'ias',
            title: 'Инженерная служба',
            actions: [
                {
                    title: '+ ВС',
                    type: 'newIasAircraft'
                },
                {
                    title: 'События ВС',
                    type: 'iasAircraftEvents'
                },
                {
                    title: '+ Событие ВС',
                    type: 'newIasAicraftEvent'
                }
            ]
        },
        {
            id: 'pds',
            title: 'Диспетчерская служба',
            actions: [
                {
                    title: 'Рейсы',
                    type: 'pdsFlights'
                },
                {
                    title: '+ Рейс',
                    type: 'newPdsFlight'
                }
            ]
        },
        {
            id: 'finance',
            title: 'Коммерческая служба',
            actions: [
                {
                    title: 'Заказы',
                    type: 'financeFlights'
                },
                {
                    title: 'Настройки',
                    type: 'financeDocuments'
                },
                {
                    title: 'Отчет',
                    type: 'report'
                }
            ]
        },
        {
            id: 'simple',
            title: '',
            actions: [
                {
                    title: '+ ВС',
                    type: 'newIasAircraft'
                },
                {
                    title: '+ EL',
                    type: 'newPdsFlight'
                },
                {
                    title: 'Настройки',
                    type: 'financeDocuments'
                }
            ]
        }
    ],
    searchFilter: '',
    eventTypes: [],
    eventTypeFilter: '',
    crewRoleFilter: '',
    crewRoles: [],
    data: null,
    notices: [],
    selectedNotice: null,
    reloadCounter: 0,
    updateCounter: 0,
    loading: false,
    focusEvent: null,
    updateInterval: 0,
    showWorkshifts: false
};

export interface Action {
    type: string;
    payload: any;
}

export const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case SELECT_TAB:
            return {
                ...state,
                currentTab: action.payload,
                loading: true
            };
        case LOAD_TIMELINE_OPTIONS_SUCCESS:
            return { ...state, ...action.payload };
        case LOAD_TIMELINE_DATA_REQUEST:
        case LOAD_TIMELINE_DATA_FAILURE:
        case LOAD_TIMELINE_OPTIONS_FAILURE:
            return state;
        case LOAD_TIMELINE_DATA_SUCCESS:
            let crewRoles: string[] = [];
            let data = { ...action.payload };
            const eventTypes = [
                ...new Set<string>(
                    action.payload.events.map(
                        (item: any) => item.parent || item.type
                    )
                )
            ].sort();
            if (state.currentTab === 'crew') {
                crewRoles = getCrewRoles(action.payload);
                data = sortedCrew(crewRolesToText(action.payload));
            }
            const focusEvent =
                state.focusEvent && state.focusEvent.waitingData
                    ? { ...state.focusEvent, waitingData: false }
                    : state.focusEvent;
            const notices: Notice[] = action.payload.notices
                ? action.payload.notices
                      .filter((notice: Notice) =>
                          isSuperset(
                              new Set(state.userRoles),
                              new Set(notice.roles)
                          )
                      )
                      .sort((n1: Notice, n2: Notice) =>
                          compareDesc(parseISO(n1.date), parseISO(n2.date))
                      )
                : [];
            return {
                ...state,
                crewRoles,
                eventTypes,
                data,
                notices,
                reloadCounter: state.reloadCounter + (state.loading ? 1 : 0),
                updateCounter: state.updateCounter + (state.loading ? 0 : 1),
                loading: false,
                focusEvent
            };
        case 'changeDate':
            return { ...state, ...action.payload };
        case 'searchFilterChange':
            return { ...state, searchFilter: action.payload };
        case 'eventTypeFilterChange':
            return { ...state, eventTypeFilter: action.payload };
        case 'crewRoleFilterChange':
            return { ...state, crewRoleFilter: action.payload };
        case 'noticeClose':
            return {
                ...state,
                notices: state.notices.filter(
                    (notice) => notice.id !== action.payload.id
                )
            };
        case 'reloadTimeline':
            return {
                ...state,
                reloadCounter: state.reloadCounter + 1
            };
        case 'setFocusEvent':
            return {
                ...state,
                focusEvent: action.payload
            };
        case 'waitingFocusEvent':
            return {
                ...state,
                waitingFocusEvent: action.payload
            };
        case 'setShowWorkshifts':
            return {
                ...state,
                showWorkshifts: action.payload
            };
        default:
            throw new Error(`unknown action type: ${action.type}`);
    }
};

export const onecDataHandler = (dispatch: any, data: any) => {
    switch (data.type) {
        case 'setApiConfig':
            API = apiFactory(data.payload);
            getTimelineOptions(dispatch);
    }
};

function isSuperset(set: Set<any>, subset: Set<any>) {
    for (var elem of subset) {
        if (!set.has(elem)) {
            return false;
        }
    }
    return true;
}

export const selectTab = (dispatch: any, tabId: string) => {
    dispatch({ type: SELECT_TAB, payload: tabId });
};

export const setRangePeriod = (
    dispatch: any,
    startDate: Moment,
    endDate: Moment
) => {
    dispatch({
        type: 'changeDate',
        payload: {
            startDate,
            endDate
        }
    });
};

export const selectNotice = (dispatch: any, notice: Notice) => {
    EventEmitter.dispatch('noticeSelect', notice);
    if (!notice.action_required) {
        setTimeout(() => closeNotice(dispatch, notice), 0);
    }
};

export const closeNotice = (dispatch: any, notice: Notice) => {
    dispatch({ type: 'noticeClose', payload: notice });
    API && API.closeNotice(notice.id);
};

export const reloadTimeline = (dispatch: any) => {
    dispatch({ type: 'reloadTimeline' });
};

export const handleTabAction = (type: string) => {
    oneSConnector.sendDataToOneS({ type });
};

const getCrewRoles = (data: any) => {
    const { groups } = data;
    const roles = Array.from<string>(
        new Set(
            groups.reduce(
                (allRoles: any, group: any) =>
                    group.roles ? allRoles.concat(group.roles) : allRoles,
                []
            )
        )
    ).sort();
    return roles;
};

const sortedCrew = (data: any) => {
    const { groups } = data;

    let sortedGroups = [

        ...groups
            .filter((group: any) => group.roles && group.roles.includes('КВС-Инструктор'))
            .sort(sortByName),
        
        
        ...groups
            .filter(
                (group: any) =>
                    group.roles &&
                    group.roles.includes('КВС') &&
                    !group.roles.includes('КВС-Инструктор')
            )
            .sort(sortByName),

        ...groups
            .filter(
                (group: any) =>
                    group.roles &&
                    group.roles.includes('Второй пилот') &&
                    !group.roles.includes('КВС') &&
                    !group.roles.includes('КВС-Инструктор')
            )
            .sort(sortByName),

        ...groups
            .filter(
                (group: any) =>
                    group.roles &&
                    group.roles.includes('Бортпроводник-инструктор') &&
                    !group.roles.includes('Второй пилот') &&
                    !group.roles.includes('КВС') &&
                    !group.roles.includes('КВС-Инструктор')
            )
            .sort(sortByName),

        ...groups
            .filter(
                (group: any) =>
                    group.roles &&
                    group.roles.includes('Бортпроводник') &&
                    !group.roles.includes('Бортпроводник-инструктор') &&
                    !group.roles.includes('Второй пилот') &&
                    !group.roles.includes('КВС') &&
                    !group.roles.includes('КВС-Инструктор')
            )
            .sort(sortByName)
    ];
    sortedGroups = [
        ...sortedGroups,
        ...groups
            .filter((group: any) => !sortedGroups.includes(group))
            .sort(sortByName)
    ];
    return { ...data, groups: sortedGroups };
};

const crewRolesToText = (data: any) => {
    const { groups } = data;
    return {
        ...data,
        groups: groups.map((group: any) => ({
            ...group,
            //text: group.roles ? group.roles.sort().join(', ') : group.text
            text: group.roles ? group.roles.join(', ') : group.text
        }))
    };
};

const sortByName = (a: any, b: any) =>
    a.title > b.title ? 1 : a.title < b.title ? -1 : 0;

export const EventEmitter = {
    events: {} as any,
    dispatch: function(event: any, data: any) {
        if (!this.events[event]) {
            return;
        }
        this.events[event].forEach((callback: any) => callback(data));
    },
    subscribe: function(event: any, callback: any) {
        if (!this.events[event]) {
            this.events[event] = [];
            this.events[event].push(callback);
        }
    },
    unsubscribe: function(event: any, callback: any) {
        this.events[event] = this.events[event].filter(
            (cb: any) => cb !== callback
        );
        if (this.events[event].length === 0) {
            delete this.events[event];
        }
    }
};

export const getTimelineData = async (
    dispatch: any,
    currentTab: Tab['id'],
    cancelToken: { isCancelled: boolean }
) => {
    if (!API) {
        return;
    }
    const objectType = currentTab === 'crew' ? 'crew' : 'aircraft';
    dispatch({
        type: LOAD_TIMELINE_DATA_REQUEST
    });
    let { result, error } = await API.getTimelineData({
        startDate: '2019-01-01T00:00:00Z',
        endDate: '2099-12-31T23:59:59Z',
        objectType
    });
    // КОСТЫЛИ!!! Требуется, чтобы забронированные рейсы отображались ТОЛЬКО в коммерческой вкладке
    // https://denis.1c.najet.ru/hs/rpc
    if (API.uri === "https://jetexpress.test.najet.ru/hs/rpc" || API.uri === "https://jetexpress.najet.ru/hs/rpc") {
        if (currentTab !== "finance") {
            let copy_result = JSON.parse(JSON.stringify(result))
            let groups_crew: any = []
            let events_without_booking = copy_result.events.filter(function(item: any) {
                if (item.flight !== undefined) {
                    if (item.flight.status === "Забронирован") {
                        groups_crew.push(item.groupId);
                    }
                    return item.flight.status !== "Забронирован"
                }
                return true;
            });
            copy_result.events = events_without_booking

            if (currentTab === "crew") {
                let ws_without_booking = copy_result.workshifts.filter(function(item: any) {
                    if (groups_crew.indexOf(item.groupId) === -1) {
                        return true
                    }
                    return false
                });
                copy_result.workshifts = ws_without_booking
            }
            result = copy_result;
        }
    }
    // КОНЕЦ этого УЖАСНОГО костыля

    if (cancelToken.isCancelled) {
        return;
    }
    if (result) {
        dispatch({
            type: LOAD_TIMELINE_DATA_SUCCESS,
            payload: result
        });
    }
    if (error) {
        dispatch({
            type: LOAD_TIMELINE_DATA_FAILURE,
            payload: error
        });
    }
};

export const getTimelineOptions = async (dispatch: any) => {
    if (!API) {
        return;
    }
    const { result, error } = await API.getTimelineOptions();
    if (result) {
        const options: Options = {
            updateInterval:
                result.updateInterval || initialState.updateInterval,
            userRoles: result.userRoles || initialState.userRoles,
            clientName: result.dbName || null
        };
        dispatch({ type: LOAD_TIMELINE_OPTIONS_SUCCESS, payload: options });
        if (result.userRoles && result.userRoles.length) {
            selectTab(dispatch, result.userRoles[0]);
        }
    }
    if (error) {
        dispatch({
            type: LOAD_TIMELINE_OPTIONS_FAILURE,
            payload: error
        });
    }
};
