import React from 'react';

const importAll = (require: any) =>
    require.keys().reduce((acc: any, next: string) => {
        acc[next.replace('./', '').replace(/\.[^/.]+$/, '')] = require(next);
        return acc;
    }, {});

const images = importAll(
    (require as any).context('./img/clients', false, /\.(png|jpe?g|svg)$/)
);

interface Props {
    name: string;
}

export default ({ name }: Props) => {
    return name ? (
        <img className="logo" src={images[name] || images.default} alt="" />
    ) : null;
};
