import React, { useState, useCallback, BaseSyntheticEvent } from 'react';
import moment, { Moment } from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { useStateValue } from './state';

import {
    handleTabAction,
} from './reducer';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru);
setDefaultLocale('ru');

const handleChangeDate = (
    dispatch: any,
    date: Moment,
    period: moment.unitOfTime.StartOf
) => {
    dispatch({
        type: 'changeDate',
        payload: {
            startDate: date.clone().startOf(period),
            endDate: date.clone().endOf(period)
        }
    });
};

export default () => {
    const [
        eventTypeFilterDropdownOpen,
        setEventTypeFilterDropdownOpen
    ] = useState(false);
    const [
        crewRoleFilterDropdownOpen,
        setCrewRoleFilterDropdownOpen
    ] = useState(false);
    const [state, dispatch] = useStateValue();
    // const [filterMonth, setFilterMonth] = useState(moment().startOf('month'));
    const startDate = state.startDate.clone();
    startDate.locale('ru');
    const filterMonth = startDate.startOf('month');
    const filterMonthPrev = useCallback(() => {
        const newFilterMonth = filterMonth.clone().subtract(1, 'month');
        // setFilterMonth(newFilterMonth);
        handleChangeDate(dispatch, newFilterMonth, 'month');
    }, [filterMonth, dispatch]);
    const filterMonthNext = useCallback(() => {
        const newFilterMonth = filterMonth.clone().add(1, 'month');
        // setFilterMonth(newFilterMonth);
        handleChangeDate(dispatch, newFilterMonth, 'month');
    }, [filterMonth, dispatch]);
    const handleFilterMonthClick = () => {
        handleChangeDate(dispatch, filterMonth, 'month');
    };
    const handleTodayClick = () => {
        handleChangeDate(dispatch, moment.utc(), 'day');
    };
    const handleWeekClick = () => {
        handleChangeDate(dispatch, moment.utc(), 'isoWeek');
    };
    const handleFromPeriodChange = (date: Date) => {
        const startDate = moment.utc(date).startOf('day');
        const endDate = moment.max(startDate, state.endDate);
        dispatch({ type: 'changeDate', payload: { startDate, endDate } });
    };
    const handleToPeriodChange = (date: Date) => {
        dispatch({
            type: 'changeDate',
            payload: { endDate: moment.utc(date).endOf('day') }
        });
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: 'searchFilterChange',
            payload: event.target.value
        });
    };
    const handleEventTypeSelect = (eventType: string) => {
        setEventTypeFilterDropdownOpen(false);
        dispatch({
            type: 'eventTypeFilterChange',
            payload: eventType
        });
    };
    const handleCrewRoleSelect = (crewRole: string) => {
        setCrewRoleFilterDropdownOpen(false);
        dispatch({
            type: 'crewRoleFilterChange',
            payload: crewRole
        });
    };

    const changeShowWorkshifts = (event: BaseSyntheticEvent) => {
        dispatch({
            type: 'setShowWorkshifts',
            payload: event.target.checked
        });
    };

    const handlePeriodClick = (dateFrom: string, dateTo: string)  => {
        let startDate = moment.utc(dateFrom).startOf('day');
        let endDate = moment.utc(dateTo).endOf('day');
        dispatch({ type: 'changeDate', payload: { startDate, endDate } });
    };

   // if (state.currentTab !== "simple") {
        return (
            <div>
            <div className="filter">
                <div className="filter-cell">
                    <div className="filter-row dates-filter">
                        <div className="quick-dates">
                            <button className="button" onClick={handleTodayClick}>
                                Сегодня
                            </button>
                            <button className="button" onClick={handleWeekClick}>
                                Неделя
                            </button>
                            <button className="hidden-element" id="buttonPeriod" 
                                onClick={()=>handlePeriodClick(document!.getElementById('startDate')!.title,document!.getElementById('endDate')!.title)}
                                >
                                Период
                            </button>
                            <div className="filter-month">
                                <span
                                    className="filter-month-prev"
                                    onClick={filterMonthPrev}
                                >
                                </span>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleFilterMonthClick}
                                >
                                    {filterMonth.format('MMMM YYYY')}
                                </span>
                                <span
                                    className="filter-month-next"
                                    onClick={filterMonthNext}
                                ></span>
                            </div>
                            <div className="hidden-element" id="startDate" title="01.01.2022">
                            </div>
                            <div className="hidden-element" id="endDate" title="01.02.2022">
                            </div>                            
                        </div>

                        <div className="period-dates">
                            <div className="filter-label">Период&nbsp;с</div>
                            <div className="control control-datepicker">
                                <DatePicker
                                    selected={state.startDate.toDate()}
                                    onChange={handleFromPeriodChange}
                                    dateFormat="dd.MM.yyyy"
                                    className="button"
                                    id="dateFrom"
                                />
                            </div>
                            <div className="filter-label">по</div>
                            <div className="control control-datepicker">
                                <DatePicker
                                    selected={state.endDate.toDate()}
                                    minDate={state.startDate.toDate()}
                                    onChange={handleToPeriodChange}
                                    dateFormat="dd.MM.yyyy"
                                    className="button"
                                    id="dateTo"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-row">
                        <div className="filter-label">Отфильтровать по:</div>
                        <div className="controls">
                            {state.currentTab === 'crew' && (
                                <div className="control control-width control-width-200">
                                    <button
                                        className="button button-dropdown"
                                        onClick={() =>
                                            setCrewRoleFilterDropdownOpen(
                                                (value) => !value
                                            )
                                        }
                                    >
                                        ролям сотрудников
                                        {state.crewRoleFilter &&
                                            `: ${state.crewRoleFilter}`}
                                    </button>
                                    <div
                                        className={`dropdown ${(crewRoleFilterDropdownOpen &&
                                            'open') ||
                                            ''}`}
                                    >
                                        <div
                                            className="dropdown-item"
                                            onClick={() => handleCrewRoleSelect('')}
                                        >
                                            Все
                                        </div>
                                        {state.crewRoles.map((item: any) => (
                                            <div
                                                className="dropdown-item"
                                                key={item}
                                                onClick={() =>
                                                    handleCrewRoleSelect(item)
                                                }
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className="control control-width control-width-200">
                                <button
                                    className="button button-dropdown"
                                    onClick={() =>
                                        setEventTypeFilterDropdownOpen(
                                            (value) => !value
                                        )
                                    }
                                >
                                    типу событий
                                    {state.eventTypeFilter &&
                                        `: ${state.eventTypeFilter}`}
                                </button>
                                <div
                                    className={`dropdown ${(eventTypeFilterDropdownOpen &&
                                        'open') ||
                                        ''}`}
                                >
                                    <div
                                        className="dropdown-item"
                                        onClick={() => handleEventTypeSelect('')}
                                    >
                                        Все
                                    </div>
                                    {state.eventTypes.map((item: string) => (
                                        <div
                                            className="dropdown-item"
                                            key={item}
                                            onClick={() =>
                                                handleEventTypeSelect(item)
                                            }
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {state.currentTab === 'crew' && (
                            <>
                                <label
                                    className="control-checkbox filter-label"
                                    style={{ marginLeft: 10 }}
                                >
                                    Показывать смены{' '}
                                    <input
                                        type="checkbox"
                                        checked={state.showWorkshifts}
                                        onChange={changeShowWorkshifts}
                                    />
                                    <i></i>
                                </label>
                            </>
                        )}
                        {state.currentTab === 'crew' && (
                            <div
                                style={{
                                    marginLeft: 20,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="icon icon-search"></div>
                                <input
                                    type="text"
                                    className="input"
                                    name="search"
                                    placeholder="Поиск"
                                    autoComplete="off"
                                    value={state.searchFilter}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <button className="button" onClick={() => handleTabAction('forms')}>
                Справочники
            </button>
            </div>
        );
 //   } else {return null}
};